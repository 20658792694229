export const accredited_nonprofits = `nonprofits(where:{
  accredited: true
  resolved: true
  user: {
    membership: true
  }
})
{
  kind
  id
  name
  description
  website
  cause
  subcause
  accredited
  resolved
  sida
  pida
  sharedays {
    id
    name
    amount_raised
    description
    contribution
    cause
    subcause
    startDate
    endDate
    startTime
    endTime
    address
    nonprofit {
      name
      website
      user {
        email
      }
    }
    business {
      name
      website
      location {
        address
      }
      logo {
        id
        url
      }
      user {
        email
      }
    }
  }
  logo {
    id
    url
    formats
  }
  banner {
    id
    url
    formats
  }
  location {
    address
    phone
    lat
    lng
  }
  user {
    id
    membership
    email
    pidm
    sidm
    cid
    role {
      type
    }
  }
}`;
