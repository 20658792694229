import { gql, request } from "graphql-request";
import { business_queries } from "../data/business-queries";
import { accredited_nonprofits } from "../data/nonprofit-queries";
import { page_queries } from "../data/page-queries";
import { shareday_queries } from "../data/shareday-queries";
import { taxonomy_queries } from "../data/taxonomy-queries";

const SERVER = process.env.NEXT_PUBLIC_SERVER;

export const queries = gql`{
    ${page_queries}
    ${taxonomy_queries}
    ${business_queries}
    ${shareday_queries}
    ${accredited_nonprofits}
  }`;

async function fetchData(): Promise<any> {
  const API_ENDPOINT = process.env.NEXT_PUBLIC_API_ENDPOINT as string;

  try {
    const data = await request(API_ENDPOINT, queries, {
      headers: {
        "Access-Control-Allow-Origin": SERVER,
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Vary": "Origin"
      }
    });

    return data;
  } catch (error: any) {
    return {
      error: error.message.split(":"[0]),
    };
  }
}

export default fetchData;
