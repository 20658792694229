export const shareday_queries = `sharedays (
  sort: "startDate:asc"
  where: {
    business: {
      user: {
        membership: true
      }
    }
    nonprofit: {
      accredited: true 
      resolved: true
      user: {
        membership: true
      }
    }
})
{
  kind
  id
  name
  contribution
  description
  cause
  subcause
  category
  amount_raised
  gallery {
    id
    url
    formats
  }
  startDate
  endDate
  startTime
  endTime
  note
  address
  lat
  lng
  business {
    id
    name
    description
    category
    website
    logo {
      alternativeText
      url
      formats
    }
    location {
      address
      lat
      lng
      phone
    }
    user {
      email
    }
  }
  nonprofit {
    id
    name
    ein
    description
    cause
    subcause
    website
    logo {
      alternativeText
      url
      formats
    }
    user {
      email
    }
  }
}`;
