export const business_queries = `businesses(where:{
  user: {
    membership: true
  }
})
{
  kind
  id
  name
  description
  category
  contribution
  website
  sharedays {
    id
    name
    amount_raised
    description
    contribution
    cause
    subcause
    startDate
    endDate
    startTime
    endTime
    address
    nonprofit {
      name
      website
      user {
        email
      }
    }
    business {
      name
      website
      location {
        address
      }
      logo {
        id
        url
      }
      user {
        email
      }
    }
  }
  logo {
    id
    alternativeText
    url
    formats
  }
  banner {
    id
    alternativeText
    url
    formats
  }
  location {
    address
    phone
    lat
    lng
    availability {
      from
      to
      days
    }
  }
  user {
    id
    email
    membership
    role {
      type
    }
  }
}`;
