import axios from "axios";
import Link from "next/link";
import router, { useRouter } from "next/router";
import { MutableRefObject, ReactElement, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useSWRConfig } from "swr";
import { MenuItemsProps } from "../@types/content-types";
import { useStore } from "../data/store";
import MenuDropdown, { directoryItems } from "./MenuDropdown";

const smallTablet = "(min-width: 768px) and (max-width: 1023px)";
const tablet = "(min-width: 640px) and (max-width: 767px)";

function MainMenu(): ReactElement {
  const { mutate } = useSWRConfig();
  const directory = useRef<HTMLLIElement>();
  const [showLogout, setShowLogout] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<string | null>();
  const path = useRouter().pathname;
  const lightMenu = path === "/" || path === "/about" ? true : false;
  const xs = useMediaQuery({ query: "(max-width: 639px)" });
  const sm = useMediaQuery({ query: smallTablet });
  const md = useMediaQuery({ query: tablet });
  const isMobile = xs || sm || md;
  const videoHero = path === "/" || path === "/about";

  const setShowDropdown = useStore(
    (state: { setShowDropdown: any }) => state.setShowDropdown
  );

  const showDropdown = useStore(
    (state: { showDropdown: any }) => state.showDropdown
  );

  const signIn: Array<MenuItemsProps> = !isLoggedIn
    ? [{ key: "signin", page: "Sign In", url: "/signin" }]
    : [];

  const logoutItem: Array<MenuItemsProps> =
    isMobile && isLoggedIn ? [{ key: "logout", page: "Logout" }] : [];

  const MenuItemsProps: Array<MenuItemsProps> = [
    { key: "home", page: "Home", url: "/" },
    { key: "about", page: "About", url: "/about" },
    {
      key: "sharedays",
      page: "Passion Map™",
      url: "/passion-map/sharedays",
    },
    {
      key: "directory",
      page: "Directory",
      url: "/directory",
      submenu: isMobile ? [] : directoryItems
    },
    ...(isMobile ? directoryItems : []),
    {
      key: "betterone",
      page: "Better One™",
      url: "http://betterone.org",
      rel: "noreferrer noopener",
      target: "_blank",
    },
    {
      key: isLoggedIn ? "dashboard" : "signup",
      page: isLoggedIn ? "Dashboard" : "Get Started",
      url: isLoggedIn ? "/members/dashboard" : "/signup",
    },
    ...signIn,
    ...logoutItem,
  ];

  useEffect(() => {
    setIsLoggedIn(window.localStorage.getItem("auth"));
  }, []);

  return (
    <>
      <nav
        id="main-menu"
        className={`relative portrait:fixed portrait:top-8 landscape:fixed landscape:top-8 w-12 h-12 bg-white right-10 shadow-2xl top-5 cursor-pointer rounded-full lg:flex lg:justify-between lg:Items-center lg:pt-8 lg:pb-12 lg:top-0 z-50 lg:w-full lg:auto lg:cursor-pointer lg:right-0 lg:shadow-none lg:rounded-none lg:bg-transparent ${videoHero
          ? "lg:absolute lg:p-0 lg:m-0 lg:h-0 lg:top-0 lg:bg-transparent"
          : "lg:relative"
          }`}
      >
        {isMobile && !mobileMenu ? (
          <div className="w-full h-full">
            <div
              className="w-5 h-5 mx-auto relative right-0 top-3/10"
              onClick={() => setMobileMenu(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        ) : (isMobile && mobileMenu) || !isMobile ? (
          <div
            className={
              isMobile
                ? "bg-white pt-10 pb-5 px-8 absolute top-8 right-0 rounded-3xl shadow-2xl"
                : "flex justify-between container w-full"
            }
          >
            {isMobile ? (
              <div
                className="absolute right-2 -top-6"
                onClick={() => setMobileMenu(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 top-1 right-1 relative"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            ) : null}
            <div
              className={`${isMobile ? "w-20 h-8 -mt-4" : "w-24 h-8 -mt-1"
                } bg-contain bg-no-repeat xl:ml-10`}
            >
              <img
                src={`/that1thing_logo_${lightMenu && !isMobile ? "white" : "black"
                  }.png`}
                alt="That1thing"
              />
            </div>
            <ul
              className={
                isMobile
                  ? "space-y-2 mt-3 w-24"
                  : `flex justify-end space-x-4 font-medium pl-0.5 overflow-y-visible ${lightMenu ? "text-white" : "text-normal"
                  } xl:mr-10`
              }
            >
              {MenuItemsProps.map((item) => {
                const listProps = {
                  ref: directory as MutableRefObject<HTMLLIElement>,
                  onMouseEnter: () => setShowDropdown(true),
                  onMouseLeave: () => setShowDropdown(false)
                };

                return (
                  <li
                    key={item.key}
                    {...(item.key === "directory" && !isMobile && listProps)}
                    className={`${item.key} relative group`}
                    onClick={async (event) => {
                      event.preventDefault();

                      setMobileMenu(false);

                      if (item.key === "logout") {
                        const response = await axios({
                          method: "POST",
                          url: "/api/logout",
                        });

                        mutate(response.data, false);

                        router.push("/signin");
                      }
                    }}
                  >
                    <Link
                      href={
                        item.key === "logout"
                          ? "#"
                          : encodeURI(item.url as string)
                      }
                      as={
                        item.key !== "logout"
                          ? ""
                          : encodeURI(item.url as string)
                      }
                    >
                      <a
                        className={`${isMobile ? "" : "h-8"} flex text-current hover:text-secondary`}
                        target={item.key !== "betterone" ? "_self" : "_blank"}
                      >
                        {item.key === "directory" && isMobile ? "" : item.page}
                        {item.key === "directory" && !isMobile ? (
                          <svg
                            className="ml-1 mt-0.5 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        ) : null}
                      </a>
                    </Link>
                    {showDropdown && item.key === "directory" && !isMobile ? (
                      <div
                        className="absolute right-0 h-28 top-7"
                        onMouseEnter={() => setShowDropdown(true)}
                        onMouseLeave={() => setShowDropdown(false)}
                      >
                        <div className="self-end py-2 w-36 rounded-2xl shadow-xl bg-white ring-1 ring-black ring-opacity-5 z-50">
                          <MenuDropdown />
                        </div>
                      </div>
                    ) : null}
                  </li>
                );
              })}
              {isLoggedIn && !isMobile ? (
                <li
                  onMouseEnter={() => setShowLogout(true)}
                  onMouseLeave={() => setShowLogout(false)}
                  className="relative bottom-2 -right-2 cursor-pointer flex"
                >
                  <div className="h-10 w-10 bg-primary text-white border-2 border-gray-300 rounded-full text-center font-normal tracking-wider pt-1.5">
                    {window.localStorage.getItem("initials")}
                  </div>
                  <div className="pl-1 ml-0 self-center top-5 relative">
                    <svg
                      className="mt-0.5 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </li>
              ) : (
                <li>
                  <div className={isMobile ? "h-0" : "h-10"} />
                </li>
              )}
            </ul>
          </div>
        ) : null}
        {showLogout ? (
          <>
            <div
              onMouseEnter={() => setShowLogout(true)}
              onMouseLeave={() => setShowLogout(false)}
              className="absolute w-40 right-12 top-8 pt-7"
            >
              <div className="cursor-pointer w-40 py-6 px-5 bg-white shadow-2xl rounded-2xl">
                <ul className="space-y-3">
                  <li>
                    <Link href="/members/profile">
                      <a className="hover:text-primary">Profile</a>
                    </Link>
                  </li>
                  <li
                    className="hover:text-primary"
                    onClick={async (event) => {
                      event.preventDefault();
                      window.localStorage.removeItem("auth");

                      await axios.get("/api/logout")
                      router.push("/signin");
                    }}
                  >
                    Logout
                  </li>
                </ul>
              </div>
            </div>
          </>
        ) : null}
      </nav>
    </>
  );
}

export default MainMenu;
