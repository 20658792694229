export const page_queries = `homePage 
 {
	proposition
	title
	subtitle
	video_button_url
	video_button_text
	mp4
	how {
		id_selector
		title
		desc
		Col {
			title
			desc
			img {
				url
			}
		}
	}
	who {
		id_selector
		title
		desc
		content
		mp4
		url
		img {
			url
		}
	}
	info {
		id_selector
		title
		desc
		mp4
		stats {
			number
			desc
		}
	}
	call {
		title
		desc
		button
		url
		color
	}
}
aboutPage {
	values
	brand
	image_url
	brand_image {
		url
	}
	call {
		title
		desc
		button
		url
		color
	}
	intro {
		id_selector
		title
		desc
		content
		mp4
		img {
			url
		}
	}
	mission {
		id_selector
		title
		content
	}
	profile {
		id_selector
		title
		desc
		content
		mp4
		img {
			url
		}
	}
}
businessPage {
	add_business
	add_business_description
	benefits {
		id_selector
		title 
		content 
		url 
		img {
			url
		}
		tabbedBenefits {
       	 	tab
       	 	head
       	 	content
			mp4
        	media {
          		url
				formats
        	}
		}
	}
	cta {
		statement
	}
	userQuestions {
		title_img {
			url
		}
		title
		faq {
			question
			answer 
			video_thumb {
				url 
				formats
			}
			media {
				formats
				url
			}
		}
	}
}
faqPage {
	headline
	header_img {
		url
	}
	FAQ {
		question
		answer
		video_thumb {
			url 
			formats
		}
		media {
			formats
			url
		}
	}
}
planFeature {
	business {
		feature
		free
		share_plan
		better_plan
	}
	nonprofit {
		feature
		free
		accreditation_fee
		plan_a
	}
	education {
		feature
		free
		accreditation_fee
		plan_a
	}
}
directoryPage {
	business {
        title
        caption
        url
        image {
          	url
        }
    }
	nonprofit {
		title
        caption
        url
        image {
          	url
        }
    }
}
demoPage {
	intro
	outro
	tagline
	image {
		url
	}
}
eventPage {
	headline
	button_text
	events {
		event_type
		title
		image {
			url
		}
		event_content
	}
}
individualPage {
	benefits {
		id_selector
		title 
		content 
		url 
		img {
			url
		}
		tabbedBenefits {
			tab
			head
			content
			mp4
			media {
				url
				formats
			}
		}
	}
	cta {
		statement
	}
	userQuestions {
		title_img {
			url
		}
		title
		faq {
			question
			answer 
			video_thumb {
				formats
				url
			}
			media {
				formats
				url
			}
		}
	}
}
issues {
	bug_name
	summary
	date
	email
	os
	browser
	url
	description
	screenshot {
		url
	}
	notes
}
issuesPage {
	form_header
	form_title
	form_description
	image {
		url
	}
}
mapPage {
	invite_business_title
	invite_business_description
	invite_nonprofit_title
	invite_nonprofit_description
	invite_shareday_title
	invite_shareday_description
	email_image {
		formats
		url
	}
	subject
	buttonUrl
	buttonText
	business_message
	nonprofit_message
	shareday_message
}
membershipPage {
	account_details
	accreditation_title
	accreditation_description
	make_payment
	cancel
	churnSubject
	churnMessage
	membership_title
	membership_description
	removal
	reasons {
		reason
	}
}
sharedayPage {
	shareday
	shareday_title
	shareday_request
	shareday_request_list
	past_shareday_title
	past_shareday_request_list
	sent_shareday_request_list
	pending_request_shareday_title
    shareday_request_list
	deny_shareday
	filter_title
	filter_description
	amount_raised_description_business
	amount_raised_description_nonprofit
	sent_shareday_title
	sent_shareday_description
	archieved_sent_shareday_title
	archieved_sent_shareday_description
}
nonprofitPage {
	add_nonprofit
	add_nonprofit_description
	accreditation_note
	accreditation_renewal_description
	benefits {
		id_selector
		title 
		content 
		url 
		img {
			url
		}
		tabbedBenefits {
       	 	tab
       	 	head
       	 	content
			mp4
        	media {
          		url
				formats
        	}
		}
	}
	cta {
		statement
	}
	userQuestions {
		title_img {
			url
		}
		title
		faq {
			question
			answer 
			video_thumb {
				url
				formats
			}
			media {
				formats
				url
			}
		}
	}
}
accreditationRequirementPage {
    intro
    requirementTable {
		requirements {
			requirement
			required
			nonprofits
        	educational
      	}
    }
	process {
		step
		phase
		subphase
	}
}
privacyPage {
	text
}
termsPage {
	text
}
addEntity {
	terms 
	checkout
}
footer {
	photos {
		url
	}
	mission {
		id_selector
		title
		desc
		content
		mp4
		img {
			url
		}
	}
	footer_urls {
		name
		url
	}
	newsletter {
		id_selector
		title
		desc
		Col {
			title
			desc
			img {
				url
			}
		}
	}
}`;

export const dashboard_query = `dashboard {
	image {
		url
		formats 
	}
	notifications {
		url
		role	
		header
		body
	}
}`;

export const application_review_query = `
	applicationReview {
		reviewing {
			image {
				url
				formats
			}
			header
			body
		}
		preparing {
			image {
				url
				formats
			}
			header
			body
		}
		result {
			result
			image {
				url
				formats
			}
			header
			body
			buttonUrl
			buttonText
		}
	}`;
