import Link from "next/link";
import { ReactElement } from "react";
import { MenuItem } from "../@types/component-types";
import { State, useStore } from "../data/store";

function MenuDropdown(): ReactElement {
  const setShowDropdown: State['setShowDropdown'] = useStore((state: State) => state.setShowDropdown);

  function handleClick(): void {
    setShowDropdown(false);
  }

  return (
    <div id="dropdown">
      <div
        className="py-1 font-medium"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        <div onClick={handleClick}>
          <Link href="/directory/businesses">
            <a
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
            >
              Businesses
            </a>
          </Link>
        </div>
        <div onClick={handleClick}>
          <Link href="/directory/nonprofits">
            <a
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
            >
              Nonprofits
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
}

export const directoryItems: Array<MenuItem> = [
  {
    key: "businesses",
    page: "Businesses",
    url: "/directory/businesses",
  },
  {
    key: "nonprofits",
    page: "Nonprofits",
    url: "/directory/nonprofits",
  },
]

export default MenuDropdown;
