export const pageview = (url: string) => {
    if (window !== undefined && window && window.gta) {
      window?.gtag("config", process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID, {
        page_path: url,
      });
    }
  };
  
  export const event = ({ action, params }) => {
    if (window !== undefined && window && window.gta) {
      window?.gtag("event", action, params);
    }
  };