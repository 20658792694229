import { PaymentMethod } from "@stripe/stripe-js";
import produce, { Draft } from "immer";
import create, { StateCreator } from "zustand";
import { Events } from "../@types/campaign-types";
import { Entities, Nonprofits } from "../@types/entity-types";

const log =
  (config: any) => (set: (arg0: any) => void, get: () => any, api: any) =>
    config(
      (args: any) => {
        if (/*process.env.NODE_ENV !== "production"*/ true) {
          console.log("🔂 applying", args);
          set(args);
          console.log("✅ new state", get());
        }
      },
      get,
      api
    );

const immer =
  <T extends State>(
    config: StateCreator<T, (fn: (draft: Draft<T>) => void) => void>
  ): StateCreator<T> =>
    (set, get, api) =>
      config((fn) => set(produce(fn) as (state: T) => T), get, api);

export type State = {
  // User Data
  auth: number;
  setAuth: (input: number) => void;
  businessHours: string;
  setBusinessHours: (input: string) => void;
  cardLength: number;
  setCardLength: (input: number) => void;
  currentPlan: string;
  setCurrentPlan: (input: string) => void;
  customerId: string;
  setCustomerId: (input: string) => void;
  formValues: object;
  setFormValues: (input: any) => void;
  getDefaultCard: string;
  setGetDefaultCard: (input: string) => void;
  getMembership: {
    id: string;
    price: number;
    fee: number;
    promo: string;
  };
  setGetMembership: (input: State["getMembership"]) => void;
  getSubscriptions: object;
  setGetSubscriptions: (input: object) => void;
  organizationFormComplete: boolean;
  setOrganizationFormComplete: (input: boolean) => void;
  profileID: string;
  setProfileID: (input: string) => void;
  userDataCache: object | boolean;
  setUserDataCache: (input: object | boolean) => void;
  paymentMethods: PaymentMethod[];
  setPaymentMethods: (input: any) => void;

  // Map
  data: Events;
  setData: (input: Events) => void;
  mapLocations: Events | Entities;
  setMapLocations: (input: []) => void;
  marker: string;
  setMarker: (input: string) => void;
  setMultiEvent: (input: string[]) => void;
  multiEvent: string[];
  setGroupLocations: (input: []) => void;
  groupLocations: [];
  setShowLocations: (input: boolean) => void;
  showLocations: boolean;
  setMapEvent: (input: State["mapEvent"]) => void;
  mapEvent: {
    key: string;
    visible: boolean;
    pin: string;
    latLng?: number;
  };

  // UI Data
  checkoutDescription: string;
  setCheckoutDescription: (input: string) => void;
  directoryPath: string;
  setDirectoryPath: (input: string) => void;
  paymentTerms: string;
  setPaymentTerms: (input: string) => void;
  sharedayFilter: {
    eventFilter: boolean;
    data: Events;
  };
  setSharedayFilter: (input: { eventFilter: boolean; data: Events }) => void;
  eventFromList: object;
  setEventFromList: (input: object) => void;
  list: boolean;
  setList: (input: boolean) => void;
  membershipFormRequsites: {
    plan: boolean;
    paymentMethod: boolean;
    acceptTerms: boolean;
  };
  setMembershipFormRequsites: (input: State["membershipFormRequsites"]) => void;
  multiEventView: boolean;
  setMultiEventView: (input: boolean) => void;
  organization: boolean;
  setOrganization: (input: boolean) => void;
  sharedayRequestCount: number;
  setSharedayRequestCount: (input: number) => void;
  sharedayEmail: {
    recipientName: string;
    recipientEmail: string;
    senderName: string;
  };
  setSharedayEmail: (input: State["sharedayEmail"]) => void;
  recipientName: string;
  setRecipientName: (input: string) => void;
  recipientEmail: string;
  setReciepientEmail: (input: string) => void;
  senderName: string;
  setSenderName: (input: string) => void;
  submitNonprofit: {
    evaluation: boolean;
    organization: Nonprofits["data"]["nonprofits"][0],
  };
  setSubmitNonprofit: (input: any) => void;

  // UI Components
  filter: boolean;
  setFilter: (input: boolean) => void;
  wishlist: boolean;
  setWishlist: (input: boolean) => void;
  setShowDropdown: (input: boolean) => void;
  showDropdown: boolean;
  setModal: (input: boolean) => void;
  modal: boolean;
  setNotification: (input: State["notification"]) => void;
  notification: {
    show: boolean;
    type: string;
    message: string;
  };
  setBillingFormVisible: (input: boolean) => void;
  billingFormVisible: boolean;
  setCardList: (input: any) => void;
  cardList: {
    initial: boolean;
    cards: number;
  };
  setDefaultCard: (input: string) => void;
  defaultCard: string;
  showBillingForm: boolean;
  setShowBillingForm: (input: boolean) => void;
  selectError: boolean;
  setSelectError: (input: boolean) => void;
};

export const useStore = create(
  log(
    immer((set, get) => ({
      // User Data
      auth: 0,
      setAuth: (input) => set((state) => void (state.auth = input)),
      businessHours: "",
      setBusinessHours: (input) =>
        set((state) => void (state.businessHours = input)),
      cardLength: 0,
      setCardLength: (input) => set((state) => void (state.cardLength = input)),
      getDefaultCard: "",
      setGetDefaultCard: (input) =>
        set((state) => void (state.getDefaultCard = input)),
      customerId: "",
      setCustomerId: (input) => set((state) => void (state.customerId = input)),
      formValues: {},
      setFormValues: (input) => set((state) => void (state.formValues = input)),
      getMembership: {
        id: "",
        price: 0,
        fee: 0,
        promo: "",
      },
      setGetMembership: (input) =>
        set((state) => void (state.getMembership = input)),
      getSubscriptions: [],
      setGetSubscriptions: (input) =>
        set((state) => void (state.getSubscriptions = input)),
      organizationFormComplete: false,
      setOrganizationFormComplete: (input) =>
        set((state) => void (state.organizationFormComplete = input)),
      profileID: "",
      setProfileID: (input) => set((state) => void (state.profileID = input)),
      currentPlan: "",
      setCurrentPlan: (input) =>
        set((state) => void (state.currentPlan = input)),
      userDataCache: false,
      setUserDataCache: (input) =>
        set((state) => void (state.userDataCache = input)),
      paymentMethods: [],
      setPaymentMethods: (input) =>
        set((state) => void (state.paymentMethods = input)),

      // Map
      data: [],
      setData: (input) => set((state) => void (state.data = input)),
      sharedayEmail: {
        recipientName: "",
        recipientEmail: "",
        senderName: "",
      },
      setSharedayEmail: (input) =>
        set((state) => void (state.sharedayEmail = input)),
      setMapLocations: (input) =>
        set((state) => void (state.mapLocations = input)),
      mapLocations: [],
      setMapEvent: (input) => set((state) => void (state.mapEvent = input)),
      mapEvent: {
        key: "",
        visible: false,
        pin: "",
      },
      marker: "",
      setMarker: (input) => set((state) => void (state.marker = input)),
      setGroupLocations: (input) =>
        set((state) => void (state.groupLocations = input)),
      groupLocations: [],
      setMultiEvent: (input) => set((state) => void (state.multiEvent = input)),
      multiEvent: [],
      setShowLocations: (input) =>
        set((state) => void (state.showLocations = input)),
      showLocations: false,

      // UI Data
      checkoutDescription: "",
      setCheckoutDescription: (input) =>
        set((state) => void (state.checkoutDescription = input)),
      directoryPath: "",
      setDirectoryPath: (input) =>
        set((state) => void (state.directoryPath = input)),
      paymentTerms: "",
      setPaymentTerms: (input) =>
        set((state) => void (state.paymentTerms = input)),
      sharedayFilter: {
        eventFilter: false,
        data: [],
      },
      setSharedayFilter: (input) =>
        set((state) => void (state.sharedayFilter = input)),
      eventFromList: {},
      setEventFromList: (input) =>
        set((state) => void (state.eventFromList = input)),
      list: true,
      setList: (input) => set((state) => void (state.list = input)),
      membershipFormRequsites: {
        plan: false,
        paymentMethod: false,
        acceptTerms: false,
      },
      setMembershipFormRequsites: (input) =>
        set((state) => void (state.membershipFormRequsites = input)),
      multiEventView: false,
      setMultiEventView: (input) =>
        set((state) => void (state.multiEventView = input)),
      organization: false,
      setOrganization: (input) =>
        set((state) => void (state.organization = input)),
      sharedayRequestCount: 0,
      setSharedayRequestCount: (input) =>
        set((state) => void (state.sharedayRequestCount = input)),
      recipientName: "",
      setRecipientName: (input) =>
        set((state) => void (state.recipientName = input)),
      recipientEmail: "",
      setReciepientEmail: (input) =>
        set((state) => void (state.recipientEmail = input)),
      senderName: "",
      setSenderName: (input) => set((state) => void (state.senderName = input)),
      submitNonprofit: {
        evaluation: false,
        organization: get()?.submitNonprofit?.organization,
      },
      setSubmitNonprofit: (input) => set((state) => void (state.submitNonprofit = input)),

      // UI Components
      filter: false,
      setFilter: (input) => set((state) => void (state.filter = input)),
      wishlist: false,
      setWishlist: (input) => set((state) => void (state.wishlist = input)),
      showDropdown: false,
      setShowDropdown: (input) =>
        set((state) => void (state.showDropdown = input)),
      notification: { show: false, type: "", message: "" },
      setNotification: (input) =>
        set((state) => void (state.notification = input)),
      setModal: (input) => set((state) => void (state.modal = input)),
      modal: false,
      setBillingFormVisible: (input) =>
        set((state) => void (state.billingFormVisible = input)),
      billingFormVisible: false,
      setCardList: (input) => set((state) => void (state.cardList = input)),
      cardList: {
        initial: true,
        cards: 0,
      },
      setDefaultCard: (input) =>
        set((state) => void (state.defaultCard = input)),
      defaultCard: "",
      showBillingForm: false,
      setShowBillingForm: (input) =>
        set((state) => void (state.showBillingForm = input)),
      selectError: false,
      setSelectError: (input) =>
        set((state) => void (state.selectError = input)),
    }))
  )
)