import { LoadingOutlined } from "@ant-design/icons";
import * as Sentry from "@sentry/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import type { AppProps } from 'next/app';
import Head from "next/head";
import { useRouter } from "next/router";
import { ReactElement, useEffect } from "react";
import useSWR from "swr";
import "tailwindcss/tailwind.css";
import Fallback from "../components/Fallback";
import fetchData, { queries } from "../hooks/fetchData";
import { pageview } from "../lib/ga";
import "../styles/main.css";

const stripePk = process.env.NEXT_PUBLIC_STRIPE_PK;
const stripePromise = loadStripe(stripePk as string);

const fallback = <Fallback />;

function That1Thing({ Component, pageProps }: AppProps): ReactElement {
  const { data } = useSWR(queries, fetchData);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url);
    };

    // When the component is mounted, subscribe to router changes
    // and log those page views
    router.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return data ? (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="robots" content={"noindex"} />
        <meta
          name="viewport"
          content="initial-scale=1.0, width=device-width, maximum-scale=1.0, shrink-to-fit=no"
        />
      </Head>
      <main className="m-0 p-0">
        <Elements stripe={stripePromise}>
          <Sentry.ErrorBoundary fallback={fallback} showDialog>
            <Component content={data} {...pageProps} />
          </Sentry.ErrorBoundary>
        </Elements>
      </main>
    </>
  ) : (
    <div>
      <LoadingOutlined
        style={{
          fontSize: "1rem",
          color: "#623c68",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

export default That1Thing;
